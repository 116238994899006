import { NextPage } from "next";
import Script from "next/script";
import Head from "next/head";
import type { AppProps } from "next/app";

import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { ChakraProvider } from "@chakra-ui/react";
import {
  getUserId,
  // HackleProvider
} from "@hackler/react-sdk";
import { OverlayProvider } from "@toss/use-overlay";
import { ThemeProvider } from "@emotion/react";

// import AOS from "aos";
// import "aos/dist/aos.css";

import {
  chakraTheme,
  getUserProperties,
  initUserProperties,
  theme,
  HackleClient,
} from "goi_common";

import "@/styles/portal.css";
import "@/styles/reset.css";
import "@/styles/theme/font.css";
import { GlobalStyle } from "@/styles/GlobalStyle";
import { KakaoScript } from "@/components/Scripts/KakaoScript";

import "antd/dist/antd.css";
import { Layout } from "@/components/Layout";

// import "slick-carousel/slick/slick.css";

declare global {
  interface Window {
    Kakao: any;
    // naver: any;
    hackleClinet: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const googleTagManagerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
    `;

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    // AOS.init();
    const goiMemberId = localStorage.getItem("goi-member-id");

    if (goiMemberId) setMemberId(goiMemberId);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [memberId, setMemberId] = useState("");

  useEffect(() => {
    if (HackleClient) {
      initUserProperties();
      let id = getUserId();

      if (memberId) {
        id = memberId;
      }

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);
    }
  }, [memberId]);

  const Components = (
    // <HackleProvider hackleClient={HackleClient} supportSSR>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ChakraProvider resetCSS={true} theme={chakraTheme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <OverlayProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </OverlayProvider>
            <ReactQueryDevtools />
          </Hydrate>
        </QueryClientProvider>
      </ChakraProvider>
    </ThemeProvider>
    // </HackleProvider>
  );

  return (
    <>
      <Head>
        <title>
          {pageProps.pageOgTitle ? pageProps.pageOgTitle : "고이 부고"}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: googleTagManagerScript }}
        />
      )}
      <KakaoScript />
      {Components}
    </>
  );
}

export default MyApp;
